/* eslint-disable prettier/prettier */
<template>
  <div class="home">
    <div class="flex-row justify-center height-100 width-100">
      <div class="width-25">
        <card-welcome :companyName="companyName" :beiRatingRow="beiRatingRow" />
      </div>
      <div
        class="flex-column width-75 pa-3 align-center justify-center height-100"
      >
        <!-- <div class="card-title mont-400">Today's Insight</div> -->
        <div class="card-title mont-400">Chillers Energy Consumption Today</div>
        <card-consumption :consumptionRow="consumptionRow" />
        <div class="card-title mont-400">
          Average Production Area Temperature Today
        </div>
        <card-temperature :temperatureRow="temperatureRow" />
      </div>
    </div>
  </div>
</template>

<script>
import CardWelcome from "../components/CardWelcome.vue";
import CardConsumption from "../components/CardConsumption.vue";
import CardTemperature from "../components/CardTemperature.vue";
import { COMPANY_INFO, UNITS } from "../config/index.js";
import api from "../api/index";

export default {
  name: "Home",

  components: {
    CardWelcome,
    CardConsumption,
    CardTemperature,
  },

  data() {
    return {
      interval: null,
      companyName: COMPANY_INFO.NAME,
      beiRatingRow: [],
      consumptionRow: [],
      temperatureRow: [],
    };
  },

  methods: {
    loadData() {
      this.getConsumptionData();
      this.getTemperatureData();
      this.getBeiRatingData();
    },

    async getConsumptionData() {
      try {
        let count = 0;
        let result = await api.getConsumption();

        let consumptionData = [];
        result.forEach((element) => {
          let obj = {};
          obj.id = count;
          obj.unit = "kWh";

          switch (element.group) {
            case "nexperia-chiller-1":
              obj.text = "Chiller 1";
              obj.icon = "mdi-snowflake";
              obj.color = "rgba(102, 77, 0, 0.95)";
              break;
            case "nexperia-chiller-2":
              obj.text = "Chiller 2";
              obj.icon = "mdi-snowflake";
              obj.color = "rgba(110, 42, 12, 0.95)";
              break;
            case "nexperia-chiller-3":
              obj.text = "Chiller 3";
              obj.icon = "mdi-snowflake";
              obj.color = "rgba(105, 19, 18, 0.95)";
              break;
            case "nexperia-chiller-5":
              obj.text = "Chiller 5";
              obj.icon = "mdi-snowflake";
              obj.color = "rgba(93, 9, 51, 0.95)";
              break;
            default:
              console.log("No data matched");
              break;
          }
          obj.value = element.value.toFixed(2);
          obj.yesterdayAvg = element.yesterdayAvg.toFixed(2);
          obj.sevenDaysAvg = element.sevenDaysAvg.toFixed(2);

          consumptionData.push(obj);
          count++;
        });

        this.consumptionRow = consumptionData;
      } catch (err) {
        console.log("Error!", err);
      }
    },

    async getTemperatureData() {
      try {
        let count = 0;
        let result = await api.getTemperature();

        let temperatureData = [];
        for (const element of result) {
          if (Object.keys(element).length > 0) {
            console.log(element)
            let obj = {};
            obj.id = count;
            obj.unit = "";

            switch (element.group) {
              case "1A/1B":
                obj.text = "Phase 1A/1B";
                obj.icon = "mdi-thermometer";
                obj.color = "rgba(41, 25, 56, 0.95)";
                break;
              case "2A":
                obj.text = "Phase 2A";
                obj.icon = "mdi-thermometer";
                obj.color = "rgba(4, 45, 58, 0.95)";
                break;
              case "2B":
                obj.text = "Phase 2B";
                obj.icon = "mdi-thermometer";
                obj.color = "rgba(18, 64, 60, 0.95)";
                break;
              case "2C":
                obj.text = "Phase 2C";
                obj.icon = "mdi-thermometer";
                obj.color = "rgba(71, 82, 0, 0.95)";
                break;
              default:
                console.log("No data matched");
                break;
            }
            obj.area1Name = element.area1Name;
            obj.area1Value = element.area1Value;
            obj.area2Name = element.area2Name;
            obj.area2Value = element.area2Value;
            obj.area3Name = element.area3Name;
            obj.area3Value = element.area3Value;
            obj.highest = element.highest;
            obj.highestArea = element.highestArea;
            obj.highestTemp = element.highestTemp;
            obj.highestTime = element.highestTime;
            obj.lowest = element.lowest;
            obj.lowestArea = element.lowestArea;
            obj.lowestTemp = element.lowestTemp;
            obj.lowestTime = element.lowestTime;
            temperatureData.push(obj);
            count++;
          }
        }
        this.temperatureRow = temperatureData;
      } catch (err) {
        console.log("Error!", err);
      }
    },

    async getBeiRatingData() {
      try {
        let count = 0;
        let result = await api.getBeiRating();
        // let result = {
        //   thisMonthBei: 39.667072044403035,
        //   lastMonthBei: 104.90088244571379,
        // };

        let beiRatingData = [];
        // This Month
        let thisMonth = {
          id: count,
          text: "BEI Rating",
          unit: UNITS.BEI_RATING,
          description: "avg this month",
        };
        // DO value calculation here
        thisMonth.value = result[0].thisMonthBei.toFixed(2);
        beiRatingData.push(thisMonth);
        count++;

        // Last Month
        let lastMonth = {
          id: count,
          text: "BEI Rating",
          unit: UNITS.BEI_RATING,
          description: "avg last month",
        };
        // DO value calculation here
        lastMonth.value = result[0].lastMonthBei.toFixed(2);
        beiRatingData.push(lastMonth);
        count++;

        this.beiRatingRow = beiRatingData;
      } catch (err) {
        console.log("Error!", err);
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  created() {
    this.loadData();
    this.interval = setInterval(this.loadData, 1 * 60000);
  },
};
</script>

<style scoped>
.mont-300 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300 !important;
}
.mont-400 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.height-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
}
.width-25 {
  width: 25%;
}
.width-30 {
  width: 30%;
  min-width: 600px;
}
.width-45 {
  width: 45%;
}
.width-60 {
  width: 60%;
}
.width-75 {
  width: 75%;
}
.row {
  column-gap: 12px;
}
.home {
  padding: 20px;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.card-title {
  color: white;
  margin-top: 10px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 1.5vw;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
}

@media only screen and (max-width: 1300px) {
  .width-30 {
    width: 30%;
    min-width: 500px;
  }
}
</style>
