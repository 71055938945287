import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { ROUTER } from "../config/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  // {
  //   path: "/th-sensors",
  //   name: "THSensors",
  //   beforeEnter() {
  //     window.open(ROUTER.TH_SENSORS, "_self");
  //   },
  // },
  {
    path: "/room-temperature",
    name: "RoomTemperature",
    beforeEnter() {
      window.open(ROUTER.ROOM_TEMPERATURE, "_self");
    },
  },
  {
    path: "/ahu-dashboard",
    name: "AhuDashboard",
    beforeEnter() {
      window.open(ROUTER.AHU_DASHBOARD, "_self");
    },
  },
  {
    path: "/chiller-dashboard",
    name: "ChillerDashboard",
    beforeEnter() {
      window.open(ROUTER.CHILLER_DASHBOARD, "_self");
    },
  },
  {
    path: "/pressure-dashboard",
    name: "PressureDashboard",
    beforeEnter() {
      window.open(ROUTER.PRESSURE_DASHBOARD, "_self");
    },
  },
  {
    path: "/control-page",
    name: "ControlPage",
    beforeEnter() {
      window.open(ROUTER.CONTROL_PAGE, "_self");
    },
  },
  {
    path: "/control",
    name: "Control",
    component: () => import("../views/Control.vue"),
  },
  {
    path: "/alert-log/:priority",
    name: "Alert Logs",
    beforeEnter(to, from, next) {
      const {priority} = to.params;
      window.open(`${ROUTER.ALERT_LOG}&var-priority=${priority}`, "_self")
    }
  },
  {
    path: "/logs",
    name: "Logs",
    beforeEnter() {
      window.open(ROUTER.LOGS, "_self")
    }
  },
  {
    path: "/scheduler",
    name: "Scheduler",
    beforeEnter() {
      window.open(ROUTER.SCHEDULER, "_self")
    }
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
