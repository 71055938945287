const API_SERVER = ".";
// const API_SERVER = "https://nexperia.tanandtech.com.my";

const TITLE = "EasiHVAC Dashboard";

const COMPANY_INFO = {
  NAME: "Nexperia Malaysia Sdn. Bhd.",
};

const UNITS = {
  BEI_RATING: "kWh/m²/Year",
  ENERGY: "kWh",
  WATER: "m³",
};

const ROUTER = {
  // TH_SENSORS: `${API_SERVER}/grafana/d/5w-E-7F7z/production-floors-sensors-data-analytics-details?orgId=1`,
  ROOM_TEMPERATURE: `${API_SERVER}/grafana/d/R5HLNRZgk/sensor-monitoring-all?orgId=1`,
  AHU_DASHBOARD: `${API_SERVER}/grafana/d/sSPb10mRk/data-analytics?orgId=1`,
  CHILLER_DASHBOARD: `${API_SERVER}/grafana/d/yQG9ReWgk/data-analytics?orgId=1`,
  PRESSURE_DASHBOARD: `${API_SERVER}/grafana/d/16MnsWRRk/differential-pressure-monitoring?orgId=1`,
  CONTROL_PAGE: ``,
  ALERT_LOG: `${API_SERVER}/grafana/d/OAojA_WRz/all-alerts?orgId=1`,
  SCHEDULER: `${API_SERVER}/grafana/d/2L0V8OZgz/easi-hvac-scheduler?orgId=1`,
  LOGS: `${API_SERVER}/grafana/d/uWfnZwggz/control-log?orgId=1`
};

module.exports = {
  API_SERVER,
  COMPANY_INFO,
  UNITS,
  ROUTER,
  TITLE,
};
