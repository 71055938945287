<template>
  <div class="row justify-center align-center height-100">
    <v-card
      v-for="item in temperatureRow"
      :key="item.id"
      elevation="2"
      height="90%"
      width="24%"
      dark
      :color="item.color"
    >
      <div class="card-item height-100 flex-column">
        <v-list-item class="height-100 width-100">
          <!-- <v-list-item-avatar
            class="mr-3 ml-3"
            rounded
            width="20%"
            height="100%"
            ><v-icon size="3vw">{{ item.icon }}</v-icon>
          </v-list-item-avatar> -->
          <v-list-item-content class="width-100">
            <v-list-item-title
              class="mont-400 text-wrap white--text card-title font-weight-medium text-center mb-3"
            >
              {{ item.text }} (°C)
            </v-list-item-title>
            <v-spacer style="height: 15px"></v-spacer>
            <v-list-item-title
              class="mont-300 text-wrap text-center card-desc-big mb-3 column_wrapper"
            >
              <div>
                <strong>{{ item.area1Name }}</strong
                ><v-spacer style="height: 10px"></v-spacer>
              </div>
              <div>
                <strong
                  >{{ numberWithCommas(item.area1Value) }}
                  {{ item.unit }}</strong
                >
              </div>
              <div>
                <strong>{{ item.area2Name }}</strong
                ><v-spacer style="height: 10px"></v-spacer>
              </div>
              <div>
                <strong
                  >{{ numberWithCommas(item.area2Value) }}
                  {{ item.unit }}</strong
                >
              </div>
              <div>
                <strong>{{ item.area3Name }}</strong
                ><v-spacer style="height: 10px"></v-spacer>
              </div>
              <div>
                <strong
                  >{{ numberWithCommas(item.area3Value) }}
                  {{ item.unit }}</strong
                >
              </div>
            </v-list-item-title>
            <v-spacer style="height: 15px"></v-spacer>
            <v-list-item-title class="mont-300 text-wrap text-center card-desc">
              <strong>Highest Today</strong>
            </v-list-item-title>
            <v-list-item-title
              class="mont-300 text-wrap text-center card-desc mb-3"
              ><strong>
                {{ item.highestArea }} / {{ item.highest }} (
                {{ numberWithCommas(item.highestTemp) }}
                {{ item.unit }} ) at {{ item.highestTime }}</strong
              >
            </v-list-item-title>
            <v-list-item-title class="mont-300 text-wrap text-center card-desc">
              <strong>Lowest Today</strong>
            </v-list-item-title>
            <v-list-item-title
              class="mont-300 text-wrap text-center card-desc mb-3"
            >
              <strong
                >{{ item.lowestArea }} / {{ item.lowest }} (
                {{ numberWithCommas(item.lowestTemp) }} {{ item.unit }} ) at
                {{ item.lowestTime }}</strong
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueScreenSize from "vue-screen-size";

export default {
  name: "CardTemperature",
  mixins: [VueScreenSize.VueScreenSizeMixin],
  props: {
    temperatureRow: {
      type: Array,
    },
  },
  data() {
    return {
      cardSize: 30,
    };
  },
  methods: {
    numberWithCommas(x) {
      if (!x) return '-';
      else return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,500;1,400&display=swap");

.mont-300 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300 !important;
}
.mont-400 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.card-item {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.card-title {
  font-size: 1.6vw;
}
.card-desc-big {
  font-size: 0.95vw;
}
.card-desc {
  font-size: 0.75vw;
}
.height-70 {
  height: 70%;
}
.height-100 {
  height: calc(100% - 75px);
  width: 100%;
}
.width-50 {
  width: 50%;
}
.width-100 {
  width: 100%;
}
.v-sheet.v-card {
  border-radius: 0px;
}
.v-toolbar__content {
  height: 100% !important;
}
.row {
  margin: 0px !important;
}
.column_wrapper {
  max-height: 100px;
  display: flex;
  flex-flow: column wrap;
}

@media only screen and (max-width: 2000px) {
  .card-title {
    font-size: 1.6vw;
  }
  .card-desc-big {
    font-size: 0.95vw;
  }
  .card-desc {
    font-size: 0.75vw;
  }
  .v-toolbar__content {
    height: 100% !important;
  }
  .column_wrapper {
    max-height: 70px;
    display: flex;
    flex-flow: column wrap;
  }
}
</style>
