<template>
  <div>
    <!-- toolbar -->
    <v-app-bar dark app>
      <!-- toolbar-left side -->
      <v-app-bar-nav-icon
        class="ml-0"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>
        <div class="title">{{ title }}</div>
      </v-toolbar-title>
      <div class="d-sm-flex">
        <div
          class="pa-2"
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 100px;
          "
        ></div>
      </div>
      <v-spacer></v-spacer>
      <!-- toolbar-right side -->
      <div class="flex-row">
        <div class="mt-2 mb-2 mr-4" v-if="+noDataCount > 0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon to="/alert-log/no_data" v-bind="attrs" v-on="on">
                <v-badge
                  :content="noDataCount"
                  :value="noDataCount"
                  color="#ff8300"
                  overlap
                >
                  <v-icon class="30"> mdi-database-remove-outline </v-icon>
                </v-badge>
              </v-btn>
            </template>
            <div>Today's no data alerts</div>
          </v-tooltip>
        </div>

        <div class="mt-2 mb-2 mr-4">
          <v-btn icon to="/alert-log/critical">
            <v-badge
              :content="alertCount"
              :value="alertCount"
              color="red"
              overlap
            >
              <v-icon class="30"> mdi-bell-outline </v-icon>
            </v-badge>
          </v-btn>
        </div>
        <v-divider
          class="divider d-none d-sm-flex"
          vertical
          inset
          dark
        ></v-divider>
        <div class="d-sm-flex">
          <p class="subheading grey--text" style="font-size: 0.705vw">
            powered by:
          </p>
          <div
            class="pa-2"
            style="display: flex; flex-direction: row; align-items: center"
          >
            <a
              href="https://www.tanand.com.my/"
              target="_blank"
              style="height: 90%"
            >
              <img
                style="height: 100%"
                class="mr-4"
                alt="Tanand_Logo"
                src="../assets/tanand-logo.png"
                transition="scale-transition"
                :width="logoSize"
              />
            </a>
          </div>
        </div>
      </div>
    </v-app-bar>
    <!-- drawer -->
    <v-navigation-drawer v-model="drawer" dark app :width="drawerSize">
      <drawer v-if="drawer" />
    </v-navigation-drawer>
  </div>
</template>

<script>
import Drawer from "./Drawer.vue";
import { TITLE } from "../config/index";
import VueScreenSize from "vue-screen-size";

export default {
  name: "Navbar",
  mixins: [VueScreenSize.VueScreenSizeMixin],
  props: {
    alertCount: {
      type: String,
    },
    noDataCount: {
      type: String,
    }
  },
  components: {
    Drawer,
  },

  data: () => ({
    drawer: true,
    title: TITLE,
    screenWidth: null,
    drawerSize: 256,
    logoSize: 120,
    logoSize2: 130,
  }),
  watch: {
    $vssWidth: function () {
      this.setDrawerSize();
    },
  },
  methods: {
    setDrawerSize() {
      let screenWidth = this.$vssWidth;
      if (screenWidth <= 1440) {
        this.drawerSize = 256;
        this.logoSize = 140;
        this.logoSize2 = 145;
      } else if (screenWidth <= 2000) {
        this.drawerSize = 256;
        this.logoSize = 140;
        this.logoSize2 = 155;
      } else if (screenWidth <= 2500) {
        this.drawerSize = 450;
        this.logoSize = 240;
        this.logoSize2 = 250;
      } else {
        this.drawerSize = 550;
        this.logoSize = 300;
        this.logoSize2 = 320;
      }
    },
  },
  created() {
    this.setDrawerSize();
  },
};
</script>

<style scoped>
.border {
  border-left: 4px solid rgb(255, 102, 0);
}
.divider {
  margin: 0 15px;
  border-color: white !important;
  height: 100%;
}

.theme--dark.v-app-bar.v-toolbar.v-sheet {
  height: 4vw !important;
}
.v-toolbar__title {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.title {
  font-size: 2vw !important;
}
.v-btn--icon.v-size--default .v-icon,
.v-btn--fab.v-size--default .v-icon {
  height: 1.8vw;
  font-size: 1.8vw;
  width: 1.8vw;
}
.flex-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.v-toolbar__content .v-btn.v-btn--icon.v-size--default {
  width: 3%;
}

@media only screen and (max-width: 2000px) {
  .v-navigation-drawer--fixed {
    width: 15vw;
  }
  .theme--dark.v-app-bar.v-toolbar.v-sheet {
    height: 3vw;
    left: 15vw;
  }
  .title {
    font-size: 1.6vw !important;
  }
}
</style>
