import { render, staticRenderFns } from "./CardWelcome.vue?vue&type=template&id=0ec9981c&scoped=true"
import script from "./CardWelcome.vue?vue&type=script&lang=js"
export * from "./CardWelcome.vue?vue&type=script&lang=js"
import style0 from "./CardWelcome.vue?vue&type=style&index=0&id=0ec9981c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ec9981c",
  null
  
)

export default component.exports