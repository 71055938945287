<template>
  <div style="position: relative; height: 100%">
    <v-layout column align-center justify-space-around>
      <v-flex class="mt-6 width-80">
        <div class="white--text text-left greeting">{{ greetings }}</div>
      </v-flex>
      <!-- company logo -->
      <div class="ma-2 width-80">
        <v-img
          class="align-self-start mb-2"
          src="../assets/nexperia-logo.png"
          alt="company_logo"
          max-width="450"
          contain
        />
      </div>
      <!-- time and date -->
      <div class="d-flex justify-space-between align-center width-80">
        <div class="day">
          {{ day }}
        </div>
        <div class="time">
          {{ time }}
        </div>
      </div>
    </v-layout>
    <!-- navbar menu -->
    <v-list flat class="mt-4">
      <v-list-item
        v-for="link in links"
        :key="link.text"
        router
        :to="link.route"
        active-class="border"
      >
        <v-list-item-action>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title>{{ link.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <!-- navbar menu-setting -->
      <v-list-group
        v-for="group in groups"
        :key="group.text"
        :prepend-icon="group.icon"
        no-action
        color="white"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ group.text }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <v-list-item
          v-for="child in group.items"
          :key="child.text"
          router
          :to="child.route"
          active-class="border"
        >
          <v-list-item-content>
            <v-list-item-title>{{ child.text }} zzz</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
    <v-layout column justify-space-around>
      <!-- hvac logo -->
      <div class="ma-2 width-80">
        <v-spacer style="height: 250px"></v-spacer>
        <v-img
          class="align-self-start mb-2"
          src="../assets/hvac_big.png"
          alt="company_logo"
          max-width="450"
          contain
        />
      </div>
    </v-layout>
  </div>
</template>

<script>
import VueScreenSize from "vue-screen-size";
const dayjs = require("dayjs");

export default {
  mixins: [VueScreenSize.VueScreenSizeMixin],
  data: () => ({
    time: null,
    day: null,
    greetings: null,
    interval: null,
    messages: 99,
    groups: [],
    links: [
      { icon: "mdi-home", text: "Home", route: "/" },
      // {
      //   icon: "mdi-thermometer",
      //   text: "TH Sensors",
      //   route: "/th-sensors",
      // },
      {
        icon: "mdi-thermometer",
        text: "Room Temperature",
        route: "/room-temperature",
      },
      {
        icon: "mdi-air-conditioner",
        text: "Air Handling Unit",
        route: "/ahu-dashboard",
      },
      {
        icon: "mdi-snowflake",
        text: "Chiller",
        route: "/chiller-dashboard",
      },
      {
        icon: "mdi-arrow-collapse-all",
        text: "Room Pressure",
        route: "/pressure-dashboard",
      },
      { 
        icon: "mdi-cog-outline", 
        text: "Control",
        route: "/control" 
      },
      {
        icon: "mdi-table-cog",
        text: "Logs",
        route: "/logs"
      },
      // {
      //   icon: "mdi-calendar-clock-outline",
      //   text: "Scheduler",
      //   route: "/scheduler"
      // },
    ],
  }),

  methods: {
    displayDateTime() {
      let date = new Date();
      this.time = dayjs(date).format("hh:mm A");
      this.day = dayjs(date).format("ddd, DD MMM YYYY");
      let hour = dayjs(date).hour();
      if (hour < 12) {
        this.greetings = "Good Morning";
      } else if (hour < 18) {
        this.greetings = "Good Afternoon";
      } else {
        this.greetings = "Good Evening";
      }
    },
  },

  beforeDestroy() {
    // prevent memory leak
    clearInterval(this.interval);
  },

  created() {
    this.displayDateTime();
    this.interval = setInterval(this.displayDateTime, 1 * 60000);
  },
};
</script>
<style scoped>
.width-80 {
  width: 80%;
}
.width-100 {
  width: 100%;
}
.day {
  color: white;
  font-size: 0.705vw;
}
.time {
  color: white;
  font-size: 0.705vw;
}
.border {
  border-left: 4px solid rgb(255, 102, 0);
}
.greeting {
  font-size: 1.2vw;
  font-weight: bold;
}

@media only screen and (min-width: 2000px) {
  .width-80 {
    width: 90%;
  }
  .v-list-item {
    height: 2.75vw !important;
  }

  .v-list-item__title {
    font-size: 1.1vw !important;
  }
  .v-icon {
    font-size: 1.5vw !important;
  }
  .greeting {
    font-size: 1.3vw;
    font-weight: bold;
  }
  .day {
    color: white;
    font-size: 0.85vw;
  }
  .time {
    color: white;
    font-size: 0.85vw;
  }
}
</style>
