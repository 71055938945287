import axios from "axios";
import { API_SERVER } from "../config/index";
import dayjs from "dayjs";

async function getConsumption() {
  const result = await axios.get(`${API_SERVER}/data/chillerEnergyUsage`);
  if (result.data.status === "OK") {
    return result.data.data;
  }
  return false;
}

async function getTemperature() {
  const result = await axios.get(`${API_SERVER}/data/phaseAvgTemp`);
  if (result.data.status === "OK") {
    return result.data.data;
  }
  return false;
}

async function getBeiRating() {
  const result = await axios.get(`${API_SERVER}/data/bei`);
  if (result.data.status === "OK") {
    return result.data.data;
  }
  return false;
}

async function getAlert(priority) {
  const result = await axios.get(
    `${API_SERVER}/data/alertsCount/${dayjs().startOf('day').unix()}/${dayjs().endOf('day').unix()}/${priority}`
  );
  if (result.data.status === "OK") {
    return result.data.data;
  }
  return false;
}

async function getWater() {
  const result = await axios.get(`${API_SERVER}/data/water`);
  if (result.data.status === "OK") {
    return result.data.data;
  }
  return false;
}

async function getSolar() {
  const result = await axios.get(`${API_SERVER}/data/solar`);
  if (result.data.status === "OK") {
    return result.data.data;
  }
  return false;
}

async function getEnergy() {
  const result = await axios.get(`${API_SERVER}/data/energy`);
  if (result.data.status === "OK") {
    return result.data.data;
  }
  return false;
}

export default {
  getConsumption,
  getTemperature,
  getBeiRating,
  getAlert,
  getWater,
  getSolar,
  getEnergy,
};
