<template>
  <v-app dark>
    <Navbar :alertCount="alertCount" :noDataCount="noDataCount"/>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view></router-view>
      </v-fade-transition>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import api from "./api/index";

export default {
  name: "App",
  components: {
    Navbar,
  },
  data() {
    return {
      interval: null,
      alertCount: null,
      noDataCount: null,
    };
  },
  methods: {
    async getAlertCount() {
      try {
        let alerts = await api.getAlert('critical');
        if (alerts) alerts > 99 ? (this.alertCount = "99+") : (this.alertCount = alerts);
        let noData = await api.getAlert('no_data');
        if (noData) noData > 99? (this.noDataCount = "99+") : (this.noDataCount = noData);
      } catch (err) {
        console.log("Error!", err);
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  created() {
    this.getAlertCount();
    this.interval = setInterval(this.getAlertCount, 1 * 60000);
  },
};
</script>

<style>
main {
  background-image: linear-gradient(to bottom, rgb(0, 0, 0, 0), rgb(0, 0, 0, 0)),
    url("./assets/nexperia-newbg.jpeg");
  background-size: cover;
  background-position: center;
}

.v-toolbar__content {
  height: 100% !important;
}

.v-application--is-ltr .v-badge__badge {
  height: 1vw !important;
  width: 1.7vw !important;
  inset: auto auto calc(100% - 0.7vw) calc(100% - 0.7vw) !important;
  font-size: 0.705vw;
}
@media only screen and (min-width: 2000px) {
  .v-main {
    padding-top: 3.5vw !important;
  }
}
</style>
