<template>
  <div class="row justify-center align-center height-100">
    <v-card
      v-for="item in consumptionRow"
      :key="item.id"
      elevation="2"
      height="90%"
      width="24%"
      dark
      :color="item.color"
    >
      <div class="card-item height-100 flex-column">
        <v-list-item class="height-100 width-100">
          <!-- <v-list-item-avatar
            class="mr-3 ml-3"
            rounded
            width="20%"
            height="100%"
            ><v-icon size="3vw">{{ item.icon }}</v-icon>
          </v-list-item-avatar> -->
          <v-list-item-content class="width-100">
            <v-list-item-title
              class="mont-400 text-wrap white--text card-title font-weight-medium text-center mb-3"
            >
              {{ item.text }}
            </v-list-item-title>
            <v-spacer style="height: 15px"></v-spacer>
            <v-list-item-title class="mont-300 text-wrap text-center card-desc">
              <strong>Today so far</strong>
            </v-list-item-title>
            <v-list-item-title
              class="mont-300 text-wrap text-center card-desc-big mb-3"
            >
              <strong>{{ numberWithCommas(item.value) }}</strong>
              {{ item.unit }}
            </v-list-item-title>
            <v-spacer style="height: 15px"></v-spacer>
            <v-list-item-title class="mont-300 text-wrap text-center card-desc">
              <strong>Yesterday</strong>
            </v-list-item-title>
            <v-list-item-title
              class="mont-300 text-wrap text-center card-desc mb-3"
            >
              <strong
                >{{ numberWithCommas(item.yesterdayAvg) }} {{ item.unit }} (
                {{
                  (
                    ((item.yesterdayAvg - item.value) / item.value) *
                    100
                  ).toFixed(2)
                }}% )</strong
              >
            </v-list-item-title>
            <v-list-item-title class="mont-300 text-wrap text-center card-desc">
              <strong>Last 7 Days Daily Average</strong>
            </v-list-item-title>
            <v-list-item-title
              class="mont-300 text-wrap text-center card-desc mb-3"
            >
              <strong
                >{{ numberWithCommas(item.sevenDaysAvg) }}
                {{ item.unit }}</strong
              >
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueScreenSize from "vue-screen-size";

export default {
  name: "CardConsumption",
  mixins: [VueScreenSize.VueScreenSizeMixin],
  props: {
    consumptionRow: {
      type: Array,
    },
  },
  data() {
    return {
      cardSize: 30,
    };
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,500;1,400&display=swap");

.mont-300 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300 !important;
}
.mont-400 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
}
.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.card-item {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.card-title {
  font-size: 1.8vw;
}
.card-desc-big {
  font-size: 1.8vw;
}
.card-desc {
  font-size: 0.8vw;
}
.height-70 {
  height: 70%;
}
.height-100 {
  height: calc(100% - 75px);
  width: 100%;
}
.width-100 {
  width: 100%;
}
.v-sheet.v-card {
  border-radius: 0px;
}
.v-toolbar__content {
  height: 100% !important;
}

.row {
  margin: 0px !important;
}

@media only screen and (max-width: 2000px) {
  .card-title {
    font-size: 1.8vw;
  }
  .card-desc-big {
    font-size: 1.8vw;
  }
  .card-desc {
    font-size: 0.8vw;
  }
  .v-toolbar__content {
    height: 100% !important;
  }
}
</style>
