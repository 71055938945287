<template>
  <v-container>
    <v-card
      elevation="3"
      class="card-welcome height-100"
      color="rgba(24, 93, 139, 0.95)"
      dark
    >
      <v-card-title
        class="justify-center card-title font-weight-medium width-100 pt-1 height-7"
        >WELCOME</v-card-title
      >
      <v-card-title
        class="mont-400 card-desc white--text justify-center text-center font-weight-medium mt-0 width-100 mb-12 height-5"
        >{{ companyName }}</v-card-title
      >
      <div v-for="item in beiRatingRow" :key="item.id" class="height-10">
        <v-card-subtitle
          class="card-desc mont-400 justify-center width-100 height-30"
          >{{ item.text }} ({{ item.description }}):</v-card-subtitle
        >
        <v-card-subtitle
          class="mont-400 card-subtitle text-center width-100 justify-center pt-2 height-100"
          >{{ numberWithCommas(item.value) }} {{ item.unit }}</v-card-subtitle
        >
      </div>
      <v-card-title
        class="mont-400 card-misc white--text justify-center text-center font-weight-medium mt-5 width-100 mb-12 height-5"
        >* BEI Rating is Calculated Using Estimated Production Area of 18904
        m2</v-card-title
      >
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: {
    companyName: {
      type: String,
    },
    beiRatingRow: {
      type: Array,
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>

<style scoped>
.mont-300 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 300 !important;
}
.mont-400 {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 400 !important;
}
.height-5 {
  height: 5%;
  line-height: 1.3;
}
.height-7 {
  height: 7%;
}
.height-10 {
  height: 10%;
}
.height-30 {
  height: 30%;
  margin-bottom: 0px;
}
.height-70 {
  height: 70%;
}
.height-100 {
  height: 100% !important;
}
.width-100 {
  width: 100%;
}
.card-title {
  font-size: 2vw;
}
.card-subtitle {
  font-size: 1.4vw;
}
.card-misc {
  font-size: 0.5vw;
}
.card-desc {
  font-size: 1vw;
}
.divider {
  border-color: white !important;
  height: 100%;
}
.container {
  width: 100% !important;
  height: 100% !important;
}
.card-welcome {
  margin: 4px;
  padding: 30px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.v-sheet.v-card {
  border-radius: 0px;
}

@media only screen and (max-width: 2000px) {
  .height-30 {
    margin-bottom: 10px;
  }
}
</style>
